import React, { useState } from "react";
import { ProductPageContainer } from "./styles";
import BreadCrumBox from "../../components/common/BreadCrumbBox";
import {
      CommonContainer,
      CommonWrapper,
} from "../../styles/components/common/CommonStyle";
import { Col, Row } from "react-bootstrap";
import ProductCategory from "./components/ProductCategory";
import ProductList from "./components/ProductList/ProductList";
import ProductFilter from "./components/ProductFilter";

const ProductPage = () => {
      const [list, setList] = useState(false);
      return (
            <>
                  <BreadCrumBox title={"Air Purifier"} />
                  <ProductPageContainer>
                        <CommonWrapper>
                              <CommonContainer>
                                    <Row>
                                          <Col lg={3}>
                                                <ProductCategory />
                                          </Col>
                                          <Col>
                                                <ProductFilter
                                                      setList={setList}
                                                      list={list}
                                                />
                                                <ProductList list={list} />
                                          </Col>
                                    </Row>
                              </CommonContainer>
                        </CommonWrapper>
                  </ProductPageContainer>
            </>
      );
};

export default ProductPage;
