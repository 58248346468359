import React from "react";
import {
      CategoryItemContainer,
      CategoryItemContent,
      CategoryItemIcon,
} from "./styles";
import { Link } from "react-router-dom";

const CategoryItem = ({ category }) => {
      let linkUrl;

      if (category?.link) {
            linkUrl = category?.link;
      } else {
            linkUrl = `/product-category/${category?.slug}`;
      }

      const handleExternalLinkClick = (e, url) => {
            e.preventDefault(); // Prevent default behavior of the <a> tag
            window.open(url, "_blank", "noopener,noreferrer"); // Open in a new tab
      };
      return (
            <>
                  {category?.link ? (
                        <CategoryItemContainer
                              href={linkUrl}
                              onClick={(e) =>
                                    handleExternalLinkClick(e, linkUrl)
                              }
                        >
                              <CategoryItemIcon
                                    dangerouslySetInnerHTML={{
                                          __html: category?.icon,
                                    }}
                              />
                              <CategoryItemContent>
                                    <Link
                                          href={linkUrl}
                                          onClick={(e) =>
                                                handleExternalLinkClick(
                                                      e,
                                                      linkUrl
                                                )
                                          }
                                          className="text-center pb-4"
                                    >
                                          <h5>
                                                {category?.subtitle ||
                                                      category?.title}
                                          </h5>
                                    </Link>
                              </CategoryItemContent>
                        </CategoryItemContainer>
                  ) : (
                        <CategoryItemContainer
                              to={`/product-category/${category?.slug}`}
                        >
                              <CategoryItemIcon
                                    dangerouslySetInnerHTML={{
                                          __html: category?.icon,
                                    }}
                              />
                              <CategoryItemContent>
                                    <Link
                                          to={`/product-category/${category?.slug}`}
                                          className="text-center pb-4"
                                    >
                                          <h5>
                                                {category?.subtitle ||
                                                      category?.title}
                                          </h5>
                                    </Link>
                              </CategoryItemContent>
                        </CategoryItemContainer>
                  )}
            </>
      );
};

export default CategoryItem;
