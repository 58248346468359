import React from 'react';

const YoutubeVideo = () => {
   return (
      <>
         <iframe
            width="100%"
            height="514"
            src="https://www.youtube.com/embed/T_0BN11GvF8?autoplay=1&mute=1&cc_load_policy=1"
            title="SCALA2 How to get perfect water pressure"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
         ></iframe>
      </>
   );
};

export default YoutubeVideo;
