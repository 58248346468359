import styled from 'styled-components';

export const ProductCategoryWrapper = styled.div`
   #example-collapse-text {
      color: #5a5a5a;
   }
   .collapse {
      padding-bottom: 10px;
   }
`;
export const ProductCategoryCollapse = styled.div`
   cursor: pointer;
   .material-symbols-outlined {
      font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
   }
   color: #5a5a5a;
   .collapse {
      color: #5a5a5a;
   }
`;
