import React from "react";
import {
      DropDownContainer,
      DropDownList,
      DropDownSubContainer,
} from "../../../../../frontend/layouts/header/styles";
import DropDownItem from "../DropDownItem";

const DropDownBox = ({ category, about, categories, title }) => {
      let categoryList = [];
      if (title === "Shop") {
            categoryList = categories;
      } else {
            categoryList = category;
      }

      return (
            <>
                  <DropDownContainer>
                        <DropDownList>
                              {categoryList?.map((cat, index) => (
                                    <DropDownItem
                                          title={
                                                cat.subtitle !==
                                                      "Water Filtration" &&
                                                cat.subtitle
                                                      ? cat.subtitle
                                                      : cat.title
                                          }
                                          slug={cat.slug}
                                          index={index}
                                          about={about}
                                          cat={cat}
                                    >
                                          {cat?.subCategory && (
                                                <DropDownSubContainer>
                                                      <DropDownList>
                                                            {cat?.subCategory.map(
                                                                  (
                                                                        sub,
                                                                        index
                                                                  ) => (
                                                                        <DropDownItem
                                                                              title={
                                                                                    sub.title
                                                                              }
                                                                              slug={
                                                                                    sub.slug
                                                                              }
                                                                              index={
                                                                                    index
                                                                              }
                                                                        />
                                                                  )
                                                            )}
                                                      </DropDownList>
                                                </DropDownSubContainer>
                                          )}
                                    </DropDownItem>
                              ))}
                        </DropDownList>
                  </DropDownContainer>
            </>
      );
};

export default DropDownBox;
