export const lightTheme = {
   body: '#212529',
   primary: '#028FFD',
   secondary: '#F29727',
   dark: '#47579A',
   white: '#FFFFFF',
   black: '#000000',
   red: '#ff0101',
   background: '#F4F3F8',
   productBackground: '#F5F5F5',
   headerBackground: '#1F243D',
   titleColor: '#161921',
   paragraphColor: '#677289',
   ratingColor: '#FFC83E',
   cloudColor: '#cbd0e3',
   borderColor: '#00000014',
   primaryFont: "'EditechRegular', sans-serif;",
   primaryFontBold: "'EditechBold', sans-serif",
   footerColor: '#EDEDF9',
   regularFont: "'Barlow', sans-serif",
   logoColor: '#028FFD'
};

export const DarkTheme = {
   body: '#000000',
   text: '#FCF6F4',
   fontFamily: "'Poppins', sans-serif",
   textRgba: '252, 246, 244',
   bodyRgba: '0,0,0'
};
