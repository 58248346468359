import React from "react";
import { NavLink } from "react-router-dom";
import DropDownBox from "../DropDownBox";

const MenuItem = ({ title, slug, category, index, about, categories }) => {
      return (
            <>
                  <li key={index} className="list-inline-item me-0">
                        <NavLink
                              to={slug}
                              className={`d-flex align-items-center gap-1 `}
                              activeclassname="active"
                        >
                              {title}
                              {category && (
                                    <DropDownBox
                                          category={category}
                                          about={about}
                                          categories={categories}
                                          title={title}
                                    />
                              )}
                        </NavLink>
                  </li>
            </>
      );
};

export default MenuItem;
